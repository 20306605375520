export const tableCols = [
  { prop: "id", label: "Area ID" },
  { prop: "name", label: "Area Name" },
];

export const areasPolygonsMetrics = [
  {
    key: "areaId",
    label: "Area ID",
    compName: "stringMetric",
    meta: {
      placeholder: "Input Area ID",
    },
  },
  {
    key: "name",
    label: "Area Name",
    compName: "stringMetric",
    meta: {
      placeholder: "Input Area Name ",
    },
  },
  {
    key: "regionId",
    label: "Region",
    compName: "regionMetric",
  },
];
