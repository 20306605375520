<template>
  <div class="polygonsPage">
    <div class="page-header">
      <div class="page-header__wrapper">
        <div class="page-header__left">
          <h3 class="title">Polygons / Areas</h3>
        </div>
      </div>
    </div>
    <!-- Filters -->
    <MetricFilterBox
      :defaultFilterData="areasPolygonsMetrics"
      v-model="metricsList"
      @openMetric="saveMetrics"
      :clearBtnShowen="metricsList.length > 0"
      @clearFilters="clearFilters"
    >
      <metricbox
        v-for="metric in metricsList"
        :key="metric.key"
        :label="$t(metric.label)"
        @close="closeMetric(metric, metric.filterKey)"
      >
        <template slot="filterBody">
          <component
            :is="metric.compName"
            v-model="filters[metric.key]"
            @change="filtersChanged(metric.key, $event)"
            :meta="metric.meta ? metric.meta : {}"
          ></component>
        </template>
      </metricbox>
    </MetricFilterBox>
    <!-- Filters -->

    <!-- Table -->

    <styled-table :data="list" class="polygonsTable" style="width: 100%">
      <el-table-column
        v-for="col in tableCols"
        :key="col.prop"
        :prop="col.prop"
        :label="$t(col.label)"
      >
      </el-table-column>
      <el-table-column prop="boundaries" label="Has Polygon">
        <template slot-scope="prop">
          <div>
            {{ prop.row.boundaries ? "Has Polygons" : "No Polygons" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column width="160" label="Actions" fixed="right">
        <template slot-scope="scope">
          <div class="operations_btns">
            <el-button
              @click="uploadPolygonSheet(scope)"
              type="success"
              size="small"
              >Add Polygon</el-button
            >
          </div>
        </template>
      </el-table-column>
    </styled-table>
    <!-- Table -->

    <!-- Pagination -->
    <div class="u-display-flex u-justify-content--center u-top-padding--2x">
      <el-pagination
        :hide-on-single-page="false"
        background
        layout="prev, pager, next"
        :page-size="50"
        :total="pagination.totalResultsCount"
        :current-page="+currentPage"
        @current-change="handlePagination"
      ></el-pagination>
    </div>
    <!-- /Pagination -->

    <!-- Uploader Dialog -->
    <el-dialog
      width="40%"
      :title="$t('uploadEntitesSheettitle')"
      :visible.sync="uploaderDialog"
      @close="onClickCloseDialog"
    >
      <div class="uploaderDialog">
        <div class="uploaderDialogBtn__wrapper">
          <div class="uploadContainer">
            <div class="summary">
              <div class="summary__wrapper">
                <el-alert
                  style="margin-bottom: 12px"
                  :closable="false"
                  :title="summary"
                  type="warning"
                  show-icon
                >
                </el-alert>
              </div>
            </div>
            <el-upload
              ref="excel-input"
              :on-change="uploadExcelFileChange"
              :on-remove="removeExcelFile"
              :file-list="excelFileList"
              action="#"
              :multiple="false"
              :auto-upload="false"
              accept=".xlsx | .geojson | .json"
            >
              <el-button slot="trigger" size="mini" type="primary"
                >Select File</el-button
              >
            </el-upload>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          :disabled="excelFileList.length == 0"
          icon="el-icon-top"
          type="primary"
          @click="sheetActionHandler"
        >
          {{ $t("bundleImport") }}
        </el-button>
        <el-button @click="onClickCloseDialog">{{
          $t("bundleDialogCloseLabel")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- Uploader Dialog -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import styledTable from "../../components/dataContainers/styledTable";
import MetricFilterBox from "@/components/transaction/MetricFilterBox";
import metricbox from "@/components/transaction/metricbox";
import { polygonsAreasMetrics } from "@/components/transaction/metricboxComponents/index";
import { tableCols, areasPolygonsMetrics } from "./data";
import { utils } from "@/utils";
import { regionsService } from "../../services";
export default {
  name: "polygonsPage",
  components: {
    styledTable,
    MetricFilterBox,
    metricbox,
    ...polygonsAreasMetrics,
  },

  data() {
    return {
      tableCols: tableCols,
      list: [],
      updateErrorResponseObject: null,
      uploaderDialog: false,
      summary: "",
      excelFileList: [],
      sheetHolder: null,
      pagination: {},
      metricsList: [],
      filters: {},
      areasPolygonsMetrics: areasPolygonsMetrics,
      showAreaFilters: false,
    };
  },

  computed: {
    ...mapGetters({ lookups: "getAllLookups" }),
    currentPage() {
      return this.$route.query.page
        ? Number.parseInt(this.$route.query.page)
        : 1;
    },
  },

  methods: {
    uploadPolygonSheet(scope) {
      const { id, name } = scope.row;
      this.areaId = id;
      this.summary = `Upload A Polygon Sheet To ${name}`;
      this.uploaderDialog = true;
    },

    clearFilters() {
      this.list = [];
      this.filters = {};
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.areasPolygonsMetrics = this.filters;
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.metricsList = [];
      localStorage.setItem("areasPolygonsMetrics", []);
      this.reloadPage();
    },

    async reloadPage() {
      if (this.$route.fullPath != "/polygons-areas?page=1") {
        this.$router.push({
          query: {
            page: 1,
          },
        });
        return;
      }
      await this.getAreasList(this.currentPage, this.filters);
    },

    saveMetrics() {
      localStorage.setItem(
        "areasPolygonsMetrics",
        JSON.stringify(this.metricsList)
      );
    },

    closeMetric(metric) {
      _.remove(this.metricsList, (item) => {
        return item.key == metric.key;
      });
      //add check if filter exist to avoid unnecessary api calls
      if (this.filters[metric.key]) this.filtersChanged(metric.key, undefined);
      localStorage.setItem(
        "areasPolygonsMetrics",
        JSON.stringify(this.metricsList)
      );
      //fix lodash with reactivity issue
      this.metricsList = [...this.metricsList];
    },

    async filtersChanged(key, value) {
      if (_.isEmpty(value)) {
        this.filters[key] = undefined;
        this.list = [];
      }
      // save filters in session
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.areasPolygonsMetrics = { ...this.filters };
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.filters = { ...this.filters };

      if (this.$route.fullPath != "/polygons-areas?page=1") {
        this.$router.push({
          query: {
            page: 1,
          },
        });
      }
      await this.getAreasList(this.currentPage, this.filters);
    },

    async getAreasList(page = 1, filters) {
      const { regionId, name, areaId } = filters;
      try {
        if (!regionId && !name && !areaId) {
          const errorMessage = "You Should Apply Filter To Display The Areas";
          utils.popupMessageWrapper(errorMessage, "error", 3500);
          delete this.filters.regionId;
          return;
        }

        const response = await regionsService.getareas({
          filter: filters,
          page,
        });
        // eslint-disable-next-line no-unused-vars
        let responseData = response.data.areas;
        this.pagination = {
          totalPages: response.data.pages,
          totalResultsCount: response.data.count,
          nextPage: response.data.next,
        };
        this.list = responseData;
        return response;
      } catch (error) {
        const errorMessage = utils.errorMessageExtractor(error);
        utils.popupMessageWrapper(errorMessage, "error", 3500);
      }
    },

    uploadExcelFileChange(file) {
      // Allow only one file in the file list not multiple
      this.excelFileList = [file];
      const rawFile = this.excelFileList[0];
      if (!rawFile) return;
      if (/\.(csv|xlsx|geojson|json)$/.test(rawFile.name)) {
        this.sheetHolder = rawFile;
      } else {
        this.$message({
          message: this.$t("promocodesPageFileValidationErrorMessage"),
          type: "error",
          duration: 4000,
        });
        this.excelFileList = [];
      }
    },

    removeExcelFile() {
      this.excelFileList = [];
    },

    sheetActionHandler() {
      let form = new FormData();
      form.append("file", this.excelFileList[0].raw);
      let areaId = this.areaId;

      regionsService
        .uploadPolygons(areaId, form)
        .then((res) => {
          const errorFileHeadersName =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          if (
            res.status == 200 &&
            res.headers["content-type"] != errorFileHeadersName
          ) {
            this.$message({
              message: `${res.data.message ?? "file uploaded successfully"}`,
              type: "success",
              duration: 6000,
            });
          }
          if (
            res.status == 200 &&
            res.headers["content-type"] == errorFileHeadersName
          ) {
            const responseData = res.data;
            let blob = new Blob([responseData], {
              type: res.headers["content-type"],
            });
            utils.downloadBlob(blob, "Polygons_Sheet_Errors", "xlsx");
            this.$message({
              message:
                "file uploaded successfully, please check the downloaded file",
              type: "success",
              duration: 4000,
            });
          }
          if (res?.response?.status === 400) {
            this.$globalFunctions.popupMessageWrapper(
              `There is an error occurred`,
              "error",
              2000
            );
          }
        })
        .catch((err) => {
          const errorMessage = this.$globalFunctions.errorMessageExtractor(err);
          this.$globalFunctions.popupMessageWrapper(
            errorMessage,
            "error",
            2000
          );
        })
        .finally(() => {
          this.sheetHolder = null;
          this.summary = "";
          this.excelFileList = [];
          this.uploaderDialog = false;
        });
    },

    onClickCloseDialog() {
      this.uploaderDialog = false;
      this.sheetAction = null;
      this.excelFileList = [];
      this.areaId = "";
    },

    handlePagination(newPage) {
      this.getAreasList(newPage, this.filters);
      this.$router.push({
        query: {
          page: newPage,
        },
      });
    },
  },

  async mounted() {
    try {
      const sessionFilters = JSON.parse(sessionStorage.getItem("filter"));
      this.filters =
        sessionFilters && sessionFilters.areasPolygonsMetrics
          ? sessionFilters.areasPolygonsMetrics
          : {};
    } catch (error) {
      console.error("Error parsing session storage filter:", error);
      this.filters = {};
    }

    try {
      const metricsSession = JSON.parse(
        localStorage.getItem("areasPolygonsMetrics")
      );
      if (metricsSession) {
        this.metricsList = metricsSession;
      }
    } catch (error) {
      console.error("Error parsing local storage metrics:", error);
      this.metricsList = [];
    }

    await this.getAreasList(this.currentPage, this.filters);
  },
};
</script>
<style lang="scss">
.polygonsPage {
  .page-header {
    &__wrapper {
      padding: 10px !important;
    }

    .title {
      display: flex;
      justify-content: center;
      align-content: center;
      i {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fdcd12;
        font-size: 22px;
        margin-right: 4px;
      }
    }
    &__right {
      display: flex;
    }
  }
  .transactions-list-filter {
    .el-select {
      width: 420px;
    }
    .el-select__tags-text {
      white-space: initial;
    }
  }
  .polygonsTable.el-table .cell {
    direction: ltr;
  }
  .polygonsTable .table-header:nth-child(1) {
    border-top-left-radius: 8px;
  }
  .polygonsTable .table-header:last-child {
    border-top-right-radius: 8px;
  }
  .polygonsTable .cell {
    direction: rtl;
  }
  .polygonsTable .table-header {
    background: #fccd13;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
  }
}
</style>
